import React from 'react';
import PropTypes from 'prop-types';

import LinkButton from '../../../ui/link-button';

import { configureBEMClasses } from '../../../../constants/functions';

import styles from './styles.module.scss';
const classes = configureBEMClasses(styles);

function ServiceCard(props) {

    return (
        <section className={classes.card}>
            <header className={classes['card__header']}>
                <div className={classes['card__header-title']}>
                    <h3 className={classes['card__header-heading']}>{props.title}</h3>
                    <span className={classes['card__header-slash']}>/</span>
                    <strong className={classes['card__header-price']}>&#163;{props.price.toFixed(2)}</strong>
                </div>
                <LinkButton isOuterLink={true} linkClasses={classes['card__header-button']} targetUrl="_blank" url="https://booksy.com/en-gb/47331_amigos-traditional-barbers_barber-shop_651577_springhead">Book now</LinkButton>
            </header>
        </section>
    );
}

ServiceCard.propTypes = {
    title: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
};

export default ServiceCard;